import React, { Fragment } from "react";
import { Link } from "gatsby";

const Thanks = () => {
  return (
    <Fragment>
      <p>Thanks for your submission.</p>
      <Link to="/">Back to home page</Link>
    </Fragment>
  );
};

export default Thanks;
